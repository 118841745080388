.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}
.container-fluid{
  margin: 0;
  padding: 0px;
  height: 100%;
}

.dashbord-section {
  width: 80vw;
  max-width: 80%;
  height: 100%;
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
}


.dashbord-nav {
  background-color: #e5383b !important;
  z-index: 1;
}

.dashbord-nav .btn {
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  color: #fff;
}

.btn-language{
  direction: ltr;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 0px 8px;
}

aside{
  scrollbar-width: thin;
  scrollbar-color: #919191 #fff;  ;
}

html::-webkit-scrollbar ,aside::-webkit-scrollbar{
  width: 0.5vw ;
}
html::-webkit-scrollbar-thumb ,aside::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 10px 10px #919191;
  border-radius: 4px;
  
}

html::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #e5383b;
}

html::-webkit-scrollbar-track ,aside::-webkit-scrollbar-track{
  border-radius: 4px;
  box-shadow: inset 0 0 10px 10px #fff;
  margin-block: 2.5em;
}

.p-disabled, .p-component:disabled {
  opacity: 1;
  font-size: 1.1rem;
}
.col-form-label {
  font-size: 1.1rem;
  font-weight: 400;
}

.form-check-label{
  font-size: 1.2rem;
}
.p-inputnumber , .p-inputtext{
  width: 100%;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
}

.nav-z-index{
  z-index: 9999;
}